<template>
  <div class="w-full">
    <div
      class="rounded-lg border border-n-50 bg-n-0 h-full flex flex-col px-4 sm:px-6 py-8 gap-4"
      :class="[disabled ? 'opacity-60 pointer-events-none' : '']"
    >
      <div
        class="flex flex-row items-center"
        v-if="
          title ||
          description ||
          primary ||
          secondary ||
          status ||
          slots.headerActions
        "
      >
        <div v-if="title || description" class="flex flex-1 flex-col">
          <div class="text-n-800 font-heading font-bold text-xl flex-1">
            {{ title }}
          </div>
          <div v-if="description" class="pt-2 font-body text-base text-n-800">
            {{ description }}
          </div>
        </div>
        <div
          v-if="primary || secondary || status || slots.headerActions"
          class="flex-none flex items-center flex-row gap-1"
        >
          <slot name="headerActions" />
          <l-button
            v-if="primary"
            :icon="primary"
            type="primary"
            class="w-9 h-9"
            size="small"
            @click="() => emit('primary')"
          />

          <l-button
            v-if="secondary"
            :icon="secondary"
            type="primary"
            class="w-9 h-9"
            size="small"
            @click="() => emit('secondary')"
          />
          <l-status v-if="status" :status="status" />
        </div>
      </div>
      <div class="flex-1 font-body text-base">
        <slot />
      </div>
      <div
        class="flex flex-row justify-end"
        v-if="slots.extraBottomSpace || saveButton"
      >
        <div v-if="slots.extraBottomSpace" class="flex-1 flex flex-row gap-1">
          <slot name="extraBottomSpace" />
        </div>
        <div v-if="saveButton" class="flex justify-end">
          <l-button size="small" class="min-w-40" @click="() => emit('save')">{{
            saveButtonText || t('ctas.save')
          }}</l-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { useI18n } from 'vue-i18n'

import { LButton, LStatus, type LIconName } from '@last/core-ui/paprika'

export type Props = {
  title?: string
  description?: string
  saveButton?: boolean
  saveButtonText?: string
  primary?: LIconName
  secondary?: LIconName
  disabled?: boolean
  status?: 'active' | 'pending' | 'error' | 'inactive' | 'loading'
}

defineProps<Props>()

const emit = defineEmits(['save', 'primary', 'secondary'])

const { t } = useI18n()
const slots = useSlots()
</script>
