<template>
  <div>
    <l-card
      v-if="organizationBillingOptions"
      :title="$t('billing-options.title')"
    >
      <div>
        <div class="separator bg-gray-200" />
        <l-editable-value
          :label="$t('billing-options.shop-commission')"
          :model-value="organizationBillingOptions.shopCommission.toString()"
          icon="percentage"
          class="py-4"
          @update:model-value="value => update('shopCommission', value)"
        />
        <div class="separator bg-gray-200" />
        <l-editable-value
          :label="$t('billing-options.qr-commission')"
          :model-value="organizationBillingOptions.qrCommission.toString()"
          icon="percentage"
          class="py-4"
          @update:model-value="value => update('qrCommission', value)"
        />
        <l-editable-value
          :label="$t('billing-options.dataphone-commission')"
          :model-value="
            organizationBillingOptions.dataphoneCommission.toString()
          "
          icon="percentage"
          class="py-4"
          @update:model-value="value => update('dataphoneCommission', value)"
        />
      </div>
    </l-card>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { LCard } from '@last/core-ui/paprika'
import LEditableValue from '@last/core-ui/v3/components/LEditableValue.vue'
import { notification } from '@last/core-ui/v3/plugins/notification'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

const { t } = useI18n()

type OrganizationBillingOptions = {
  shopCommission: number
  qrCommission: number
  dataphoneCommission: number
}

const { selectedOrganization } = storeToRefs(useOrganizationsStore())

const organizationBillingOptions = ref<OrganizationBillingOptions>()
const organizationId = computed(() => selectedOrganization.value?.id)
watch(
  organizationId,
  async () => {
    await refreshOrganizationBillingOptions()
  },
  { immediate: true }
)

async function refreshOrganizationBillingOptions() {
  if (organizationId.value) {
    const result = await api.get(
      `/support/organization/${organizationId.value}/billing-options`
    )
    organizationBillingOptions.value = {
      ...result.data
    }
  }
}
async function updateBillingOptions() {
  try {
    if (organizationId.value) {
      await api.put(
        `/support/organization/${organizationId.value}/billing-options`,
        {
          billingOptions: organizationBillingOptions.value
        }
      )
    }
  } catch {
    notification.create({
      title: t('error'),
      type: 'error'
    })
  }
}

const minium: OrganizationBillingOptions = {
  dataphoneCommission: 0.6,
  shopCommission: 0,
  qrCommission: 0
}

function update(field: keyof OrganizationBillingOptions, value: string) {
  const numericValue = Number(value)
  if (!organizationBillingOptions.value) return
  if (numericValue < minium[field]) {
    showCommissionError(minium[field])
    return
  }
  organizationBillingOptions.value[field] = numericValue
  updateBillingOptions()
}

function showCommissionError(minValue: number) {
  notification.create({
    title: t('billing-options.commission-error-title'),
    subtitle: t('billing-options.commission-error-message', {
      value: minValue
    }),
    icon: 'close',
    type: 'error'
  })
}
</script>

<style scoped>
::v-deep .separator {
  height: 1px;
}
</style>
