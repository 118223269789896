<template>
  <div>
    <l-card :title="$t('organization-demo.title')">
      <div class="flex justify-between items-center text-blue">
        {{ $t('organization-demo.text') }}
        <l-switch
          :model-value="demoOrganization"
          @update:model-value="setDemoOrganization"
        />
      </div>
    </l-card>
  </div>
</template>
<script>
import { mapState } from 'pinia'

import { LCard, LSwitch } from '@last/core-ui/paprika'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'DemoOrganization',
  components: {
    LCard,
    LSwitch
  },
  data() {
    return {
      demoOrganization: false
    }
  },
  mounted() {
    this.demoOrganization = this.selectedOrganization?.isDemo
  },
  methods: {
    async setDemoOrganization(value) {
      await api.put(
        `/support/organization/${this.selectedOrganization.id}/demo`,
        {
          isDemo: value
        }
      )
      this.demoOrganization = value
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedOrganization'])
  },
  watch: {
    selectedOrganization() {
      this.demoOrganization = this.selectedOrganization?.isDemo
    }
  }
}
</script>
