<template>
  <l-card :title="$t('global-settings.organization-admin-title')">
    <div class="flex flex-row justify-between items-center">
      <div class="text-gray-400">
        {{ $t('global-settings.organization-admin-message') }}
      </div>
      <div
        class="border border-violet text-violet rounded-lg px-5 py-2 w-1/4 text-center cursor-pointer"
        @click="goToAdmin"
      >
        {{ $t('global-settings.organization-admin-title') }}
      </div>
    </div>
  </l-card>
  <l-card :title="$t('global-settings.organization-pos-title')">
    <div class="flex flex-row justify-between items-center">
      <div class="text-gray-400">
        {{ $t('global-settings.organization-pos-message') }}
      </div>
      <div class="w-1/4">
        <div
          class="border border-violet text-violet rounded-lg text-center py-2 cursor-pointer"
          @click="goToPOS"
        >
          {{ $t('global-settings.organization-pos-cta') }}
        </div>
      </div>
    </div>
  </l-card>
</template>

<script>
import { mapState } from 'pinia'

import { LCard } from '@last/core-ui/paprika'

import { useAuthStore } from '@/store/auth.ts'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'SupportAccess',
  methods: {
    goToAdmin() {
      window.open(
        `${import.meta.env.VITE_LAST_ADMIN_URL}/support/login?supportToken=${
          this.accessToken
        }&organizationId=${this.selectedOrganization.id}${
          this.selectedLocation?.id !== -1
            ? `&locationId=${this.selectedLocation?.id}`
            : ''
        }`
      )
    },
    goToPOS() {
      const url = import.meta.env.VITE_LAST_POS_URL
      window.open(
        `${url}/#/support-login?supportToken=${
          this.accessToken
        }&organizationId=${this.selectedOrganization.id}&refreshLocalStorage${
          this.selectedLocation?.id !== -1
            ? `&locationId=${this.selectedLocation?.id}`
            : ''
        }`
      )
    }
  },
  computed: {
    ...mapState(useAuthStore, ['accessToken']),
    ...mapState(useOrganizationsStore, [
      'selectedOrganization',
      'selectedLocation'
    ])
  },
  components: {
    LCard
  }
}
</script>
