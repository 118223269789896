<template>
  <div class="flex flex-row text-blue py-5 border-b border-gray-200">
    <div class="flex-1">{{ payoutRow.periodStart }}</div>
    <div class="flex-1">{{ payoutRow.periodEnd }}</div>
    <div class="flex-1">{{ $filters.currency(payoutRow.fee) }}</div>
    <div class="flex-1">{{ $filters.currency(payoutRow.total) }}</div>
    <div class="flex flex-2">
      <div class="flex-1">
        <div
          v-if="!loading"
          class="text-red underline cursor-pointer"
          @click="regeneratePayouts(payoutRow)"
        >
          {{ $t('billing.regenerate') }}
        </div>
        <div v-else class="flex items-center text-gray-400">
          <icon name="loading-spinner" class="animate-spin mr-2" small />
          Cargando...
        </div>
      </div>
      <div
        class="flex-1 text-red underline cursor-pointer"
        @click="downloadXML(payoutRow)"
      >
        {{ $t('billing.download-xml') }}
      </div>
      <div
        class="flex-1 text-red underline cursor-pointer"
        :class="{
          'opacity-50 pointer-events-none': !payoutRow.canSendEmail
        }"
        @click="sendPayoutsMails(payoutRow.periodId)"
      >
        {{ $t('billing.send-mail') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@last/core-ui/paprika'

import api from '../api'

export default {
  name: 'PayoutRow',
  props: {
    payoutRow: {
      type: Object,
      default: null
    }
  },
  components: {
    Icon
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async regeneratePayouts(payoutRow) {
      this.loading = true
      await api.post('support/payouts', { periodId: payoutRow.periodId })
      this.loading = false
      this.$emit('refresh')
    },
    async downloadXML(payoutRow) {
      const response = await api.get('/support/payouts/download', {
        params: {
          periodId: payoutRow.periodId
        }
      })
      const blob = new Blob([response.data], { type: 'text/plain' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${payoutRow.periodStart}-${payoutRow.periodEnd}.xml`
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async sendPayoutsMails(periodId) {
      await api.post('/support/payouts/send-mails', { periodId })
      this.$emit('refresh')
    }
  }
}
</script>
