import './style.css'
import './devCode.js'
import './filters.js'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { LDialogPlugin, LNotificationPlugin } from '@last/core-ui/paprika'

import App from './App.vue'
import { createFilters } from './filters'
import i18n from './i18n'
import router from './router'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)

app.use(LNotificationPlugin)
app.use(LDialogPlugin)
app.use(router)
app.use(i18n)

const filters = createFilters()
app.config.globalProperties.$filters = filters
app.provide('filters', filters)

app.mount('#app')
