<template>
  <l-card :title="$t('tabs.delete-tab')" overflow-visible>
    <div class="flex flex-row justify-between items-center">
      <div class="flex-1 mr-10">
        <l-date-selector
          v-model="fromDate"
          :placeholder="$t('tabs.delete-start-date')"
        />
      </div>
      <div class="flex-1 mr-10">
        <l-date-selector
          v-model="toDate"
          :placeholder="$t('tabs.delete-end-date')"
        />
      </div>
      <div
        class="border border-violet text-violet rounded-lg w-1/4 text-center py-2 cursor-pointer flex justify-center"
        :class="{ 'opacity-50': !fromDate || !toDate }"
        @click="deleteTabs"
      >
        {{ $t('tabs.delete-tab') }}
      </div>
    </div>
  </l-card>
</template>

<script>
import { mapState } from 'pinia'

import { LCard } from '@last/core-ui/paprika'
import LDateSelector from '@last/core-ui/v3/components/LDateSelector.vue'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'DeleteTab',
  components: {
    LCard,
    LDateSelector
  },
  data() {
    return {
      fromDate: '',
      toDate: ''
    }
  },
  methods: {
    async deleteTabs() {
      try {
        await api.delete(
          `/support/location/${this.selectedLocation.id}/tabs/${new Date(
            this.fromDate
          )}/${new Date(this.toDate)}/delete`
        )
        this.$lnotification.create({
          title: this.$t('tabs.delete-tabs-success'),
          icon: 'success',
          iconColor: 'green'
        })
        this.fromDate = ''
        this.toDate = ''
      } catch (e) {
        this.$lnotification.create({
          title: this.$t('tabs.delete-tabs-failed'),
          subtitle: e.response.data,
          icon: 'close',
          iconColor: 'red'
        })
      }
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedLocation'])
  }
}
</script>
