import moment from 'moment'
import { v4 as uuid4 } from 'uuid'
import { createApp, hasInjectionContext, inject, ref } from 'vue'

import NotificationFixedList from '@last/core-ui/v3/components/NotificationFixedList.vue'
import NotificationFloatingList from '@last/core-ui/v3/components/NotificationFloatingList.vue'

import i18n from '@/i18n.js'

class NotificationList {
  constructor() {
    const app = createApp({
      data: () => ({
        fixedComponent: null,
        floatingComponent: null,
        fixedNotifications: [],
        floatingNotifications: []
      }),
      render: () => {},
      methods: {
        create(notificationData) {
          const notification = ref(new Notification(notificationData)).value
          if (!this.floatingComponent) {
            this.floatingComponent = this.openFloatingComponent({
              notifications: this.floatingNotifications,
              onAction: notification.onAction,
              onDeleteNotification: notificationIndex => {
                this.floatingNotifications.splice(notificationIndex, 1)
              }
            })
          }
          if (
            !this.fixedComponent &&
            !this.notificationAlreadyExists(notification.title)
          ) {
            this.floatingNotifications.unshift(notification)
          }
          if (notification.keepInScreen) {
            this.fixedNotifications.unshift(notification)
          }
          return notification
        },
        updateProgress(id, progress) {
          const notificationIndex = this.floatingNotifications.findIndex(
            notification => notification.id === id
          )
          if (this.floatingNotifications[notificationIndex]) {
            this.floatingNotifications[notificationIndex].setProgress(progress)
          }
        },
        delete(id) {
          const notificationIndex = this.floatingNotifications.findIndex(
            notification => notification.id === id
          )
          if (this.floatingNotifications[notificationIndex]) {
            this.floatingNotifications.splice(notificationIndex, 1)
          }
        },
        openFloatingComponent(propsData) {
          const floating = createApp(NotificationFloatingList, propsData)
          const container = document.createElement('div')
          document.body.appendChild(container)
          floating.use(i18n)
          return floating.mount(container)
        },
        openFixedComponent() {
          if (!this.fixedComponent) {
            if (this.floatingComponent) {
              this.floatingComponent.notifications = []
              this.floatingComponent = null
              this.floatingNotifications = []
            }

            const fixed = createApp(NotificationFixedList, {
              notifications: this.fixedNotifications,
              onClose: () => (this.fixedComponent = null),
              onDeleteNotification: () => notificationIndex => {
                this.fixedNotifications.splice(notificationIndex, 1)
              },
              onClear: () => {
                this.fixedNotifications = []
                this.fixedComponent.notifications = []
              }
            })
            const container = document.createElement('div')
            document.body.appendChild(container)
            fixed.use(i18n)
            fixed.mount(container)
          }
        },
        notificationAlreadyExists(title) {
          return this.floatingNotifications.some(
            notification => notification.title === title
          )
        }
      }
    })
    if (typeof document !== 'undefined') {
      const parent = document.getElementById('app') || document.body
      const container = document.createElement('div')
      parent.appendChild(container)
      this.VM = app.mount(container)
    }
  }
  get state() {
    return this.VM.$data
  }
  get create() {
    return this.VM.create
  }
  get updateProgress() {
    return this.VM.updateProgress
  }
  get delete() {
    return this.VM.delete
  }
  get fixedNotifications() {
    return this.VM.$data.fixedNotifications
  }
  get openFixedComponent() {
    return this.VM.openFixedComponent
  }
}

class Notification {
  id = uuid4()
  icon = null
  iconColor = null
  title = ''
  subtitle = null
  subtitleIcon = null
  timeout = null
  keepInScreen = false
  onAction = null
  progress = null
  progressCancelled = false
  progressCancelTitle = null
  type = 'standard'
  createdAt = moment()

  constructor(notification) {
    this.icon = notification.icon
    this.iconColor = notification.iconColor
    this.title = notification.title
    this.subtitle = notification.subtitle
    this.subtitleIcon = notification.subtitleIcon
    this.timeout = notification.timeout
    this.keepInScreen = !!notification.keepInScreen
    this.onAction = notification.onAction
    this.progress = notification.progress
    this.progressCancelled = !!notification.progressCancelled
    this.progressCancelTitle = notification.progressCancelTitle
    this.type = notification.type
  }

  setProgress(progress) {
    this.progress = progress
  }
}

export const notification = new NotificationList()

const notificationPlugin = {
  install(app) {
    app.config.globalProperties.$lnotification = notification
    app.provide('$lnotification', notification)
  }
}

export function useNotification() {
  const dialogInstance = hasInjectionContext()
    ? inject<typeof notification>('$lnotification')
    : notification

  if (!dialogInstance) {
    throw new Error('LNotificationPlugin is not installed')
  }

  return dialogInstance
}

export default notificationPlugin
