<template>
  <div class="flex flex-row items-center w-1/2">
    <div
      class="flex flex-row items-center text-blue mr-3 text-2xl"
      :class="{ 'w-full': editingMode }"
    >
      <div v-if="!editingMode">{{ editingName }}</div>
      <div v-else class="w-full">
        <l-input v-model="editingName" />
      </div>
    </div>
    <div class="text-gray-400 cursor-pointer">
      <icon v-if="!editingMode" name="pen" @click="editingMode = true" />
      <div v-else class="flex flex-row">
        <icon
          name="check"
          class="text-violet ml-2 cursor-pointer"
          @click="updateName"
        />
        <icon
          name="close"
          class="text-gray-400 ml-1 cursor-pointer"
          @click="cancelEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'

import { Icon, LInput } from '@last/core-ui/paprika'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'OrganizationName',
  data() {
    return {
      editingMode: false,
      editingName: ''
    }
  },
  mounted() {
    this.editingName = this.organization.name
  },
  props: {
    organization: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions(useOrganizationsStore, ['refreshOrganizations']),
    cancelEdit() {
      this.editingName = this.organization.name
      this.editingMode = false
    },
    async updateName() {
      await api.put(
        `/support/organization/${this.organization.id}/name/update`,
        {
          newName: this.editingName
        }
      )
      await this.refreshOrganizations()
      this.editingMode = false
    }
  },
  components: {
    Icon,
    LInput
  },
  watch: {
    organization() {
      this.editingName = this.organization.name
    }
  }
}
</script>
