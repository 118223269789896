<template>
  <transition-group
    name="list"
    tag="div"
    class="relative inline-flex flex-col overflow-y-scroll scrolling-touch pb-30 pointer-events-none"
  >
    <l-notification
      v-for="(notification, index) in notifications"
      :key="notification.id"
      class="notification-item transition grow-0 pointer-events-auto"
      :notification="notification"
      :allow-block-delete="allowBlockDelete"
      @notification-deleted="deleteNotification(index)"
      @progress-canceled="progressCanceled(index)"
    />
  </transition-group>
</template>

<script>
import LNotification from './LNotification.vue'
export default {
  name: 'NotificationList',
  components: {
    LNotification
  },
  emits: ['deleteNotification', 'progressCanceled'],
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    allowBlockDelete: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteNotification(index) {
      this.$emit('deleteNotification', index)
    },
    progressCanceled(index) {
      this.$emit('progressCanceled', index)
    }
  }
}
</script>

<style scoped>
@media (min-width: 640px) {
  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }
}
@media (max-width: 640px) {
  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateY(-100%);
  }
  .list-leave-active {
    left: 0;
    right: 0;
  }
}
.notification-item.transition {
  transition: all 0.6s;
}
.pb-30 {
  padding-bottom: 7.5rem;
}
</style>
