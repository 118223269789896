<template>
  <div>
    <l-card :title="'Last Payments'">
      <div class="flex flex-col">
        <l-button
          :disabled="loading"
          :class="{
            'pointer-events-none': loading
          }"
          @click="refreshInfo"
        >
          Refresh info
        </l-button>
        <span v-if="errorMessage" class="text-red">
          {{ errorMessage }}
        </span>
        <template v-if="show">
          <l-button
            v-if="step === 'request'"
            :loading="requesting"
            @click="requestPermission"
          >
            Request
          </l-button>
          <div v-else-if="step === 'sign'">Waiting for user to sign</div>
          <l-button
            v-if="step === 'location'"
            :loading="requesting"
            @click="createAdyenLocation"
          >
            Request
          </l-button>
          <div v-else-if="step === 'done'">Done!</div>
          <div v-if="step === 'done'" class="flex flex-col mt-4">
            <div class="flex flex-row">
              <l-select v-model="selectedDevice" :options="devicesOptions" />
              <l-button
                :disabled="!selectedDevice || requesting"
                :class="{
                  'pointer-events-none': !selectedDevice || requesting
                }"
                @click="assignDevice"
              >
                Add
              </l-button>
            </div>
            <div
              v-for="device in adyenDevices"
              :key="device.id"
              class="hover:bg-gray-100 p-2 border-b border-gray-200 flex items-center justify-between"
            >
              {{ device.poiid }}
              <icon
                name="trash"
                class="cursor-pointer text-gray-400"
                @click="() => deleteDevice(device.id)"
              />
            </div>
          </div>
        </template>
      </div>
    </l-card>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { Icon, LButton, LCard, LSelect } from '@last/core-ui/paprika'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'LastPayments',
  components: {
    LCard,
    LButton,
    Icon,
    LSelect
  },
  props: {},
  data() {
    return {
      show: false,
      receivePayments: {},
      requesting: false,
      adyenDevices: [],
      errorMessage: null,
      adyenLocation: null,
      loading: false,
      unassignedDevices: [],
      selectedDevice: null
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedLocation']),
    step() {
      if (!this.receivePayments || this.loading) return null
      if (!this.receivePayments.requested) {
        return 'request'
      }
      if (!this.receivePayments.allowed) {
        return 'sign'
      }
      if (!this.adyenLocation) {
        return 'location'
      }
      return 'done'
    },
    devicesOptions() {
      return this.unassignedDevices.map(deviceId => ({
        label: deviceId,
        value: deviceId
      }))
    }
  },
  watch: {
    selectedLocation() {
      this.show = false
    }
  },
  methods: {
    async refreshInfo() {
      if (this.loading) return
      this.show = true
      this.loading = true
      try {
        await this.refreshPermissions()
        if (!this.receivePayments.allowed) {
          this.loading = false
          return
        }
        await this.refreshLocation()
        if (!this.adyenLocation) {
          this.loading = false
          return
        }
        await this.refreshAdyenDevices()
        await this.refreshUnassignedDevices()
      } catch (e) {
        this.errorMessage = e?.response?.data ?? 'Error fetching data'
      }
      this.loading = false
    },
    async refreshPermissions() {
      try {
        const res = await api.get(
          `/support/last-payments/${this.selectedLocation.id}/permissions/receive-payments`
        )
        this.receivePayments = res.data?.receivePayments ?? {}
      } catch (e) {
        this.errorMessage = e?.response?.data ?? 'Error fetching permissions'
        this.receivePayments = {}
      }
    },
    async refreshAdyenDevices() {
      try {
        const res = await api.get(
          `/support/last-payments/${this.selectedLocation.id}/devices`
        )
        this.adyenDevices = res.data ?? []
      } catch (_e) {
        this.adyenDevices = []
      }
    },
    async refreshLocation() {
      try {
        const res = await api.get(
          `/support/last-payments/${this.selectedLocation.id}/store`
        )
        this.adyenLocation = res.data ?? null
      } catch (e) {
        this.errorMessage = e?.response?.data ?? 'Error fetching location'
        this.adyenLocation = null
      }
    },
    async requestPermission() {
      try {
        this.requesting = true
        await api.post(
          `/support/last-payments/${this.selectedLocation.id}/permissions/receive-payments`
        )
        await this.refreshPermissions()
      } catch {
        this.$lnotification.create({
          title: 'error',
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.requesting = false
    },
    async assignDevice() {
      try {
        this.requesting = true
        await api.post(
          `/support/last-payments/${this.selectedLocation.id}/device`,
          {
            deviceId: this.selectedDevice
          }
        )
      } catch {
        this.$lnotification.create({
          title: 'error',
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.requesting = false
    },
    async createAdyenLocation() {
      try {
        this.requesting = true
        await api.post(
          `/support/last-payments/${this.selectedLocation.id}/store`
        )
        await this.refreshLocation()
      } catch {
        this.$lnotification.create({
          title: 'error',
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.requesting = false
    },
    async refreshUnassignedDevices() {
      try {
        const res = await api.get(`/support/last-payments/devices`)
        this.unassignedDevices = res.data ?? []
      } catch {
        return []
      }
    },
    deleteDevice(deviceId) {
      this.$ldialog({
        title: `Eliminar dispositivo ${deviceId}`,
        onConfirm: async () => {
          await api.delete(
            `/support/last-payments/${this.selectedLocation.id}/devices/${deviceId}`
          )
          await this.refreshAdyenDevices()
        }
      })
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
