<template>
  <l-card :title="$t('integrations.title')">
    <div v-if="showIntegrations">
      <l-collapse
        v-for="(integrationList, integrationKey) in filteredIntegrations"
        :key="integrationKey"
        class="mt-8 first:mt-0"
      >
        <template #trigger="props">
          <div class="flex flex-row w-full justify-between">
            <div class="flex flex-row">
              <icon
                :name="'arrow-right'"
                class="arrow"
                :class="{
                  rotated: props.open
                }"
              />
              <div class="ml-2 font-bold text-base">
                {{ integrationList[0].company }}
              </div>
            </div>
          </div>
        </template>
        <div class="text-blue ml-5 mt-4">
          <div class="flex flex-row text-gray-400 mb-3">
            <div class="w-1/4">Service</div>
            <div class="flex-1">Configuration</div>
            <div
              v-if="isCatalogIntegrated(integrationList[0])"
              class="w-1/2 flex justify-center -mr-8"
            >
              {{ $t('integrations.last-catalog-update') }}
            </div>
          </div>
          <integration-row
            v-for="integration in integrationList"
            :key="integration.id"
            :integration="integration"
            :large="true"
            @update="updateIntegration"
            @delete="deleteIntegration"
          />
        </div>
      </l-collapse>
    </div>
  </l-card>
</template>

<script>
import { mapState } from 'pinia'

import { Icon, LCard } from '@last/core-ui/paprika'
import LCollapse from '@last/core-ui/v3/components/LCollapse.vue'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

import IntegrationRow from './IntegrationRow.vue'

export default {
  name: 'IntegrationSettings',
  components: {
    LCard,
    LCollapse,
    Icon,
    IntegrationRow
  },
  props: {
    currentIntegrations: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    async handleCopy(text) {
      await navigator.clipboard.writeText(text)
      this.$lnotification.create({
        title: this.$t('inputs.copied-clipboard'),
        icon: 'check',
        iconColor: 'green'
      })
    },
    async updateIntegration(integration) {
      await api.post(`/support/saveIntegration`, { ...integration })
      await this.$emit('refreshVirtualBrands')
    },
    deleteIntegration(integration) {
      const source = integration.key ?? integration.source
      const id = integration.integrationId ?? integration.id
      this.$ldialog({
        title: this.$t('integrations.delete-integration-title'),
        content: this.$t('integrations.delete-integration-question'),
        mainLabel: this.$t('ctas.delete'),
        icon: 'trash',
        onConfirm: async () => {
          try {
            await api.delete(
              `/support/integration-services/${source}/integrations/${id}`
            )
          } catch (_e) {
            this.$lnotification.create({
              title: 'Error',
              icon: 'error',
              iconColor: 'red'
            })
          }
          await this.$emit('refreshVirtualBrands')
        }
      })
    },
    noBrands(integration) {
      return integration.locationBrands.length === 0
    },
    isCatalogIntegrated(integration) {
      return integration.capabilities?.includes('catalogIntegrated')
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, [
      'selectedLocation',
      'selectedOrganization'
    ]),
    ...mapState('integrations', ['integrations']),
    showIntegrations() {
      return Object.keys(this.currentIntegrations).length
    },
    filteredIntegrations() {
      const integrations = Object.entries(this.currentIntegrations).reduce(
        (res, [source, integrationList]) => {
          if (integrationList[0].supportParams) res[source] = integrationList
          return res
        },
        {}
      )
      return integrations
    }
  }
}
</script>

<style scoped>
.arrow {
  transition: all 0.3s;
}

.rotated {
  transform: rotate(90deg);
}

.separator {
  height: 1px;
}
</style>
