import { defineStore } from 'pinia'

import api from '@/api'

interface Organization {
  id: number
  name: string
}

interface VirtualBrand {
  id: number
  name: string
}

interface Location {
  id: number
  name: string
}

interface OrganizationsState {
  organizations: Organization[]
  virtualBrands: VirtualBrand[]
  selectedOrganization: Organization | null
  selectedLocation: Location | null
}

export const useOrganizationsStore = defineStore('organizations', {
  state: (): OrganizationsState => ({
    organizations: [],
    virtualBrands: [],
    selectedOrganization: null,
    selectedLocation: null
  }),
  actions: {
    async refreshOrganizations(): Promise<void> {
      const response = await api.get('/support/organizations')
      this.organizations = response.data.organizations
      this.virtualBrands = response.data.virtualBrands
    },
    selectOrganization(organization: Organization): void {
      this.selectedOrganization = organization
    },
    selectLocation(location: Location) {
      this.selectedLocation = location
    },
    removeSelectedOrganization() {
      this.selectedOrganization = null
      this.selectedLocation = null
    }
  }
})
