<template>
  <div class="px-5">
    <div class="text-xl text-blue uppercase mt-10">
      {{ $t('menu.settings') }}
    </div>
    <pos-error-manager />
  </div>
</template>

<script>
import PosErrorManager from '@/components/PosErrorManager.vue'

export default {
  name: 'Settings',
  components: { PosErrorManager }
}
</script>
