<template>
  <l-card
    :title="$t('organization-shops.title')"
    overflow-visible
    primary="plus"
    @primary="addDomain"
  >
    <div
      class="flex py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <div class="flex-1 flex-shrink-0">
        {{ $t('organization-shops.brand') }}
      </div>
      <div class="flex-1 flex-shrink-0">
        {{ $t('organization-shops.domain') }}
      </div>
      <div class="w-32"></div>
    </div>
    <div
      class="flex py-3 text-blue"
      v-for="domain in domains"
      :key="domain.name"
    >
      <div class="flex-1 flex-shrink-0">
        {{ domain.brandName }}
      </div>
      <div class="flex-1 flex-shrink-0">
        {{ domain.domain }}
      </div>
      <div class="w-32 flex justify-end">
        <icon
          name="trash"
          class="text-blue cursor-pointer"
          @click="deleteDomain(domain.domain)"
        />
      </div>
    </div>
    <div class="flex py-3 text-blue items-center" v-if="addingDomain">
      <div class="flex-1 flex-shrink-0 pr-6">
        <l-select :options="brands" v-model="newDomain.brandId" />
      </div>
      <div class="flex-1 flex-shrink-0 pr-6">
        <l-input v-model="newDomain.domain" />
      </div>
      <div class="w-32 flex justify-end items-center" v-if="!creating">
        <icon
          name="check"
          class="text-violet cursor-pointer mr-2"
          @click="createDomain"
        />
        <icon name="close" class="text-blue cursor-pointer" @click="cancel" />
      </div>
      <div class="w-32 flex justify-end items-center" v-else>
        <div class="w-6 mr-4">
          <vue3-lottie :animation-data="LoadingAnimation" />
        </div>
        {{ $t('organization-shops.creating') }}
      </div>
    </div>
  </l-card>
</template>

<script>
import { mapState } from 'pinia'
import { Vue3Lottie } from 'vue3-lottie'

import LoadingAnimation from '@last/core-ui/components/assets/lottie/lottie-bar-loading.json'
import { Icon, LCard, LInput, LSelect } from '@last/core-ui/paprika'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'OrganizationShopDomains',
  components: {
    LCard,
    LInput,
    LSelect,
    Icon,
    Vue3Lottie
  },
  data() {
    return {
      domains: [],
      brands: [],
      addingDomain: false,
      creating: false,
      newDomain: {},
      LoadingAnimation
    }
  },
  async mounted() {
    await this.refreshDomains()
  },
  methods: {
    async refreshDomains() {
      if (this.selectedOrganization) {
        const result = await api.get(
          `/support/organization/${this.selectedOrganization.id}/shop-domains`
        )
        this.domains = result.data.domains
        this.brands = result.data.brands.map(brand => ({
          value: brand.id,
          label: brand.name
        }))
      }
    },
    async deleteDomain(domain) {
      await api.delete(
        `/support/organization/${this.selectedOrganization.id}/shop-domains/${domain}`
      )
      await this.refreshDomains()
    },
    async createDomain() {
      this.creating = true
      try {
        await api.post(
          `/support/organization/${this.selectedOrganization.id}/shop-domains`,
          this.newDomain
        )
        await this.refreshDomains()
        this.addingDomain = false
      } catch {
        this.$lnotification.create({
          title: this.$t('organization-shops.error'),
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.creating = false
    },
    cancel() {
      this.addingDomain = false
    },
    addDomain() {
      this.addingDomain = true
      this.newDomain = {
        domain: null,
        brandId: null
      }
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedOrganization'])
  },
  watch: {
    async selectedOrganization() {
      await this.refreshDomains()
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
