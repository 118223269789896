import axios from 'axios'

import { useAuthStore } from '@/store/auth'

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})

api.interceptors.request.use(
  config => {
    const auth = useAuthStore()
    const token = auth.accessToken
    if (token) {
      config.headers['Authorization'] = token
    }

    return config
  },

  error => {
    return Promise.reject(error)
  }
)

export default api
