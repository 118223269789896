<template>
  <import-external-catalog
    source="Klikin"
    @import-requested="importCatalog"
    :importing="importing"
  />
</template>

<script>
import { mapState } from 'pinia'

import api from '@/api'
import { useAuthStore } from '@/store/auth.ts'
import { useOrganizationsStore } from '@/store/organizations.ts'

import ImportExternalCatalog from './ImportExternalCatalog.vue'

export default {
  name: 'ImportKlikinCatalog',
  components: {
    ImportExternalCatalog
  },
  emits: ['import-requested'],
  data() {
    return {
      importing: false
    }
  },
  methods: {
    async importCatalog({ url, name }) {
      this.importing = true
      try {
        await api.post(`support/klikin-catalog-import`, {
          url: url,
          catalogName: name,
          organizationId: this.selectedOrganization.id
        })
        window.open(
          `${import.meta.env.VITE_LAST_ADMIN_URL}/support/login?supportToken=${this.accessToken}&organizationId=${this.selectedOrganization.id}&redirect=catalogs`
        )
      } catch (err) {
        this.$lnotification.create({
          title: `${this.$t('import-external-catalog.import-failed')}: ${
            err.response.request.response
          }`,
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.importing = false
    }
  },
  computed: {
    ...mapState(useAuthStore, ['accessToken']),
    ...mapState(useOrganizationsStore, ['selectedOrganization'])
  }
}
</script>
