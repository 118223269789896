<template>
  <div
    class="z-10 absolute inset-0 w-full h-full"
    @click="() => undefined"
    v-if="open"
  ></div>
  <div class="z-40 absolute top-0 right-0 h-full overflow-y-scroll dark">
    <transition name="move" @after-enter="focusSidePanel">
      <div
        v-if="open"
        ref="sidePanel"
        class="bg-blue-700 w-screen sm:w-[43.75rem] h-full flex flex-col text-white relative overflow-y-hidden"
        tabindex="-1"
        @keydown.esc="close"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const sidePanel = ref()
withDefaults(defineProps<{ open: boolean }>(), { open: false })

const emit = defineEmits(['close'])

function close() {
  emit('close')
}
function focusSidePanel() {
  if (sidePanel.value) {
    sidePanel.value.focus()
  }
}
</script>

<style scoped>
.move-enter-active,
.move-leave-active {
  transition: transform 0.5s;
}
.move-enter-from,
.move-leave-to {
  transform: translateX(100%);
}
</style>
